import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb aspecte de coral de color groguenc (més blanc de jove i marró cremós amb l’edat) i base blanquinosa. Pot mesurar fins a 20 cm de diàmetre per 12 cm d’altura. Estan formats per un peu gruixut i ample d’on surten branques que es ramifiquen en dues punxes curtes. Les espores són color crema ocre en massa, hialines, el·líptiques, rugoses, de 10-15 x 5-6,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      